dmx.Component('chart', {

    attributes: {
        width: {
            type: Number,
            default: 800
        },

        height: {
            type: Number,
            default: 600
        },

        responsive: {
            type: Boolean,
            default: false
        },

        type: {
            type: String,
            default: 'line' // line/area/bar/horizontalBar/pie/doughnut/radar/polarArea
        },

        colors: {
            type: [String, Array],
            default: 'default'
        },

        legend: {
            type: String,
            default: ''
        },

        data: {
            type: Array,
            default: []
        },

        labels: {
            type: String,
            default: ''
        },

        nogrid: {
            type: Boolean,
            default: false
        },

        points: { // line/area/radar
            type: Boolean,
            default: false
        },

        'point-style': { // line/area/radar
            type: String,
            default: 'circle' //'circle','cross','crossRot','dash','line','rect','rectRounded','rectRot','star','triangle'
        },

        'point-size': { // line/area/radar
            type: String,
            default: 3
        },

        smooth: { // line/area/radar (smooth lines)
            type: Boolean,
            default: false
        },

        thickness: { // all
            type: Number,
            default: 1
        },

        dashed: { // line/area/radar
            type: Boolean,
            default: false
        },

        stacked: { // line/area/bar/horizontalBar
            type: Boolean,
            default: false
        },

        multicolor: { // bar/horizontalBar
            type: Boolean,
            default: false
        },

        cutout: { // doughnut
            type: Number,
            default: 50 // percent
        },

        noanimation: { // all
            type: Boolean,
            default: false // disable animations
        },

        fullbar: { // bar/horizontalBar
            type: Boolean,
            default: false // make bars full size
        }
    },

    colors: {
        default: ['#1c9f8d', '#d94712', '#2d81b9', '#1aa042', '#ad1999', '#d89515', '#d83148', '#7e2dad', '#828280', '#ad312f', '#1c9f8d', '#d94712', '#2d81b9', '#1aa042', '#ad1999', '#d89515', '#d83148', '#7e2dad', '#828280', '#ad312f'],
        colors1: ['#5DA5DA', '#FAA43A', '#60BD68', '#F17CB0', '#B2912F', '#B276B2', '#DECF3F', '#F15854', '#4D4D4D', '#5DA5DA', '#FAA43A', '#60BD68', '#F17CB0', '#B2912F', '#B276B2', '#DECF3F', '#F15854', '#4D4D4D'],
        colors2: ['#5cbae6', '#b6d957', '#fac364', '#d998cb', '#f2d249', '#93b9c6', '#ccc5a8', '#52bacc', '#98aafb', '#5cbae6', '#b6d957', '#fac364', '#d998cb', '#f2d249', '#93b9c6', '#ccc5a8', '#52bacc', '#98aafb'],
        colors3: ['#3678b3', '#f47d0d', '#479f2f', '#ca2227', '#9068bc', '#87564b', '#da77c1' ,'#7f7f7f' ,'#bbbc26' ,'#4bbfcf', '#3678b3', '#f47d0d', '#479f2f', '#ca2227', '#9068bc', '#87564b', '#da77c1' ,'#7f7f7f' ,'#bbbc26' ,'#4bbfcf'],
        colors4: ['#b1c7e8', '#f8b978', '#a2df8b', '#f69795', '#c3b0d5', '#bf9b94', '#f1b5d2' ,'#c7c7c7' ,'#dbda8e' ,'#a6dae5', '#b1c7e8', '#f8b978', '#a2df8b', '#f69795', '#c3b0d5', '#bf9b94', '#f1b5d2' ,'#c7c7c7' ,'#dbda8e' ,'#a6dae5'],
        colors5: ['#3a3d79', '#66793a', '#886c32', '#7e3c39', '#764273', '#4383bd', '#db520a', '#4ba355', '#746cb0', '#636363', '#3a3d79', '#66793a', '#886c32', '#7e3c39', '#764273', '#4383bd', '#db520a', '#4ba355', '#746cb0', '#636363'],
        colors6: ['#5356a2', '#8ea153', '#b99d3b', '#a5484a', '#9e5293', '#76afd6', '#f38b3c', '#80c377', '#9e9ac8', '#969696', '#5356a2', '#8ea153', '#b99d3b', '#a5484a', '#9e5293', '#76afd6', '#f38b3c', '#80c377', '#9e9ac8', '#969696'],
        colors7: ['#6c70ce', '#b8ce6c', '#e2b853', '#cc606b', '#c66ebc', '#a4cae1', '#f5ac6b', '#a8d99b', '#bcbddc', '#bdbdbd', '#6c70ce', '#b8ce6c', '#e2b853', '#cc606b', '#c66ebc', '#a4cae1', '#f5ac6b', '#a8d99b', '#bcbddc', '#bdbdbd'],
        colors8: ['#9c9fde', '#cfda9c', '#e4ca94', '#df959b', '#d89ed5', '#c9dbef', '#f8cfa2', '#cbe9c0', '#dadaeb', '#d9d9d9', '#9c9fde', '#cfda9c', '#e4ca94', '#df959b', '#d89ed5', '#c9dbef', '#f8cfa2', '#cbe9c0', '#dadaeb', '#d9d9d9'],
        colors9: ['#f44336', '#8bc34a', '#03a9f4', '#ffc107', '#e91e63', '#cddc39', '#00bcd4', '#ff9800', '#9c27b0', '#009688', '#f44336', '#8bc34a', '#03a9f4', '#ffc107', '#e91e63', '#cddc39', '#00bcd4', '#ff9800', '#9c27b0', '#009688']
    },

    render: function(node) {
        this.$node = document.createElement('canvas');
        this.$node.setAttribute('width', this.props.width);
        this.$node.setAttribute('height', this.props.height);
        this.$node.id = this.name;

        dmx.dom.replace(node, this.$node);

        if (typeof this.props.colors == 'string') {
            this.props.colors = this.colors[this.props.colors] || this.colors.default;
        }

        this.datasets = this.getDatasets(node);

        this.update({});
    },

    update: function(props) {
        var options = {
            type: this.props.type == 'area' ? 'line' : this.props.type,
            options: {
                responsive: this.props.responsive,
                layout: { padding: 5 },
                legend: { display: false }
            }
        };

        if (typeof this.props.colors == 'string') {
            this.props.colors = this.colors[this.props.colors] || this.colors.default;
        }

        if (this.props.noanimation) {
            options.options.animation = { duration: 0 };
            options.options.hover = { animationDuration: 0 };
        }

        if (this.props.legend) {
            options.options.legend.display = true;
            options.options.legend.position = this.props.legend;
        }

        if (this.props.type == 'line' || this.props.type == 'area') {
            options.options.scales = {
                xAxes: [{ gridLines: { display: !this.props.nogrid }}],
                yAxes: [{ gridLines: { display: !this.props.nogrid }, stacked: this.props.stacked, ticks: { beginAtZero: true }}]
            };

            this.datasets.forEach(function(dataset, i) {
                var color = this.props.colors[i];

                Object.assign(dataset, {
                    fill: this.props.type == 'area',
                    lineTension: this.props.smooth ? 0.4 : 0,
                    backgroundColor: Color(color).alpha(0.5).rgbaString(),
                    borderWidth: +this.props.thickness,
                    borderColor: color,
                    borderDash: this.props.dashed ? [5,5] : [],
                    pointStyle: this.props['point-style'],
                    pointBackgroundColor: color,
                    pointBorderColor: color,
                    pointBorderWidth: 1,
                    pointHitRadius: 10,
                    pointRadius: this.props.points ? +this.props['point-size'] : 0,
                    pointHoverRadius: +this.props['point-size']
                });
            }, this);
        }

        if (this.props.type == 'bar' || this.props.type == 'horizontalBar') {
            options.options.scales = {
                xAxes: [{ gridLines: { display: !this.props.nogrid }, stacked: this.props.stacked, ticks: { beginAtZero: true }, barPercentage: this.props.fullbar ? 1 : .9, categoryPercentage: this.props.fullbar ? 1 : .8 }],
                yAxes: [{ gridLines: { display: !this.props.nogrid }, stacked: this.props.stacked, ticks: { beginAtZero: true }, barPercentage: this.props.fullbar ? 1 : .9, categoryPercentage: this.props.fullbar ? 1 : .8 }]
            };

            this.datasets.forEach(function(dataset, i) {
                var color = this.props.colors[i];

                Object.assign(dataset, {
                    backgroundColor: this.props.multicolor ? this.props.colors.map(function(color) { return Color(color).alpha(0.5).rgbaString(); }) : Color(color).alpha(0.5).rgbaString(),
                    borderColor: this.props.multicolor ? this.props.colors : color,
                    borderDash: this.props.dashed ? [5,5] : [],
                    borderWidth: +this.props.thickness
                });
            }, this);
        }

        if (this.props.type == 'polarArea' || this.props.type == 'radar') {
            options.options.scale = { gridLines: { display: !this.props.nogrid }, ticks: { beginAtZero: true } };

            this.datasets.forEach(function(dataset, i) {
                var color = this.props.colors[i];

                Object.assign(dataset, {
                    fill: true,
                    lineTension: this.props.smooth ? 0.4 : 0,
                    borderWidth: +this.props.thickness,
                    borderDash: this.props.dashed ? [5,5] : [],
                    borderColor: this.props.type == 'polarArea' ? this.props.colors : color,
                    backgroundColor: this.props.type == 'polarArea' ? this.props.colors.map(function(color) { return Color(color).alpha(0.3).rgbaString(); }) : Color(color).alpha(0.3).rgbaString(),
                    pointStyle: this.props['point-style'],
                    pointBackgroundColor: this.props.type == 'polarArea' ? this.props.colors : color,
                    pointBorderColor: this.props.type == 'polarArea' ? this.props.colors : color,
                    pointBorderWidth: 1,
                    pointHitRadius: 10,
                    pointRadius: this.props.points ? +this.props['point-size'] : 0,
                    pointHoverRadius: +this.props['point-size']
                });
            }, this);
        }

        if (this.props.type == 'pie' || this.props.type == 'doughnut') {
          this.datasets.forEach(function(dataset, i) {
              var color = this.props.colors[i];

              Object.assign(dataset, {
                  backgroundColor: this.props.colors.map(function(color) { return Color(color).alpha(0.5).rgbaString() }),
                  borderColor: this.props.colors,
                  borderWidth: +this.props.thickness,
              });
          }, this);
        }

        if (this.props.type == 'doughnut') {
            options.options.cutoutPercentage = this.props.cutout;
        }

        if (JSON.stringify(options) != JSON.stringify(this.options)) {
            if (this.props.type) {
                this.options = dmx.clone(options);

                options.data = this.getData();

                if (this.chart) this.chart.destroy();
                this.chart = new Chart(this.$node, dmx.clone(options));
            }
        } else if (this.chart) {
            var data = this.getData();
            var needUpdate = false;

            if (JSON.stringify(this.chart.data.labels) != JSON.stringify(data.labels)) {
                this.chart.data.labels = data.labels;
                needUpdate = true;
            }

            if (data.datasets.length != this.chart.data.datasets.length) {
                this.chart.data.datasets = data.datasets;
                needUpdate = true;
            } else {
                data.datasets.forEach(function(dataset, i) {
                    ['label', 'backgroundColor', 'borderColor', 'borderWidth', 'dataExpression', 'data'].forEach(function(prop) {
                        if (JSON.stringify(this.chart.data.datasets[i][prop]) != JSON.stringify(dataset[prop])) {
                            this.chart.data.datasets[i][prop] = dataset[prop];
                            needUpdate = true;
                        }
                    }, this);
                }, this);
            }

            if (needUpdate) {
                this.chart.update();
            }
        }
    },

    getDatasets: function(node) {
        var datasets = [];

        for (var i = 1; i <= 20; i++) {
            if (node.hasAttribute('dataset-' + i + ':value')) {
                datasets.push({
                    label: node.hasAttribute('dataset-' + i + ':label') ? node.getAttribute('dataset-' + i + ':label') : 'dataset ' + i,
                    backgroundColor: this.props.colors.map(function(color) { return Color(color).alpha(0.5).rgbaString() }),
                    borderColor: this.props.colors,
                    borderWidth: 1,
                    dataExpression: node.getAttribute('dataset-' + i + ':value'),
                    data: []
                });
            }
        }

        return datasets;
    },

    getData: function() {
        this.datasets.map(function(dataset) {
            var items = dmx.repeatItems(this.props.data);

            dataset.data = items.map(function(data) {
                return +dmx.parse(dataset.dataExpression, new dmx.DataScope(data));
            });

            return [];
        }, this);

        return {
            labels: this.getLabels(),
            datasets: this.datasets
        };
    },

    getLabels: function() {
        var items = dmx.repeatItems(this.props.data);

        return items.map(function(data, index) {
            if (this.props.labels) {
                return dmx.parse(this.props.labels, new dmx.DataScope(data));
            } else {
                return 'value ' + (index + 1);
            }
        }, this);
    }

});
